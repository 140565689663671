import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import api_amr from '../../services/api_amr';
import { getToken } from '../../utils/utils';
import api from '../../services/api';

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  container: {
    marginTop: theme.spacing(2),
  },
  headerCell: {
    fontSize: '0.7rem',
    whiteSpace: 'nowrap',
    overflow: 'hidden',

  },
}));

const RegistradorPrecos = () => {
  const classes = useStyles();
  const [materias, setMaterias] = useState([]);
  const [cidades, setCidades] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [fontes, setFontes] = useState([]);
  const [portos, setPortos] = useState([]);
  const [embalagens, setEmbalagems] = useState([
    { value: 'Big Bag', label: 'Big Bag' },
    { value: 'Granel', label: 'Granel' },
  ]);
  const [data, setData] = useState(new Date().toISOString().split("T")[0]);
  const [dataPagamento, setDataPagamento] = useState(new Date().toISOString().split("T")[0]);
  const [materiaSelecionada, setMateriaSelecionada] = useState(null);
  const [cidadeSelecionada, setCidadeSelecionada] = useState(null);
  const [empresaSelecionada, SetEmpresaSelecionada] = useState(null);
  const [fonteSelecionada, setFonteSelecionada] = useState(null);
  const [portoSelecionado, setPortoSelecionado] = useState(null);
  const [frete, setFrete] = useState(0);
  const [precoCompra, setPrecoCompra] = useState(0);
  const [dolar, setDolar] = useState(0);
  const [embalagem, setEmbalagem] = useState(null);
  const [volume, setVolume] = useState(0);
  const [jurosBRL, setJurosBRL] = useState(0);
  const [jurosUSD, setJurosUSD] = useState(0);
  const [isAssociado, setIsAssociado] = useState(false);
  const [associados, setAssociados] = useState([]);
  const [associado, setAssociado] = useState(null);
  const [carregando, setCarregando] = useState(false);
  const [atualizar, setAtualizar] = useState(false);
  const [precos, setPrecos] = useState([]);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    fetchMyApi();
    fetchPrecos();
  }, []);

  const fetchMyApi = async () => {
    try {
      const respose_dolar_hora = await api.get('/DolarApi');
      setDolar(respose_dolar_hora.data.toString());

      setCarregando(true);
      const token = await getToken();
      const headers = { headers: { 'x-api-key': token } };

      const responses = await Promise.all([
        api_amr.get('/return_indice_materias', headers),
        api_amr.get('/return_cidades_brasil', headers),
        api_amr.get('/retorna_empresa_fonte', headers),
        api_amr.get('/return_portos', headers),
        api_amr.get('/get_associados', headers),
      ]);

      const [responseMaterias, responseCidades, responseEmpresasFontes, responsePortos, responseAssociados] = responses;

      if (responseMaterias.status === 200) {
        setMaterias(responseMaterias.data.map(item => ({ value: item.id, label: item.descNome })));
      } else {
        alert('Ocorreu um erro ao retornar a lista de matérias');
      }

      if (responseCidades.status === 200) {
        setCidades(responseCidades.data.map(item => ({ value: item.id, label: `${item.cidade}/${item.UF}` })));
      } else {
        alert('Ocorreu um erro ao retornar a lista de cidades');
      }

      if (responseEmpresasFontes.status === 200) {
        setEmpresas(responseEmpresasFontes.data.empresas.map(item => ({ value: item.empresa, label: item.empresa })));
        setFontes(responseEmpresasFontes.data.fontes.map(item => ({ value: item.fonte_dados, label: item.fonte_dados })));
      } else {
        alert('Ocorreu um erro ao retornar a lista de empresas e fontes');
      }

      if (responsePortos.status === 200) {
        setPortos(responsePortos.data.map(item => ({ value: item.id, label: item.nome_porto })));
      } else {
        alert('Ocorreu um erro ao retornar a lista de portos');
      }

      if (responseAssociados.status === 200) {
        setAssociados(responseAssociados.data.map(item => ({ value: item.id_associado, label: item.name_associado })));
      } else {
        alert('Ocorreu um erro ao retornar a lista de Associados');
      }

      setCarregando(false);
    } catch (error) {
      console.error('Erro ao buscar dados:', error);
      setCarregando(false);
      alert('Ocorreu um erro ao buscar os dados.');
    }
  };

  const fetchPrecos = async () => {
    try {
      const response = await api_amr.get('/return_precos_registrados', { headers: { 'x-api-key': await getToken() } });
      setPrecos(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleAdicionaPreco = async () => {
    if (!materiaSelecionada || !cidadeSelecionada || !empresaSelecionada || !portoSelecionado || !embalagem || !precoCompra || (isAssociado && !associado)) {


      alert('Por favor, preencha todos os campos obrigatórios.');
      return;
    }

    setAtualizar(true);
    const response = await api_amr.post("/registra_preco_venda", {
      data: data,
      data_pagamento: dataPagamento,
      empresa: empresaSelecionada,
      id_porto: portoSelecionado.value,
      origem: cidadeSelecionada.value,
      destino: cidadeSelecionada.value,
      produto: materiaSelecionada.value,
      fonte_dados: fonteSelecionada,
      frete: frete,
      preco_compra: precoCompra,
      dolar: dolar,
      embalagem: embalagem.value,
      volume: volume,
      juros_brl: jurosBRL,
      juros_usd: jurosUSD,
      isAssociado: isAssociado,
      associado: associado,
    },
      { headers: { 'x-api-key': await getToken() } });

    if (response.data === 'OK') {
      setAtualizar(false);
      fetchPrecos(); // Atualiza a tabela após registrar o novo preço
      alert('Preço registrado com sucesso');
    } else {
      setAtualizar(false);
      alert('Ocorreu um erro, tente novamente');
    }
  };

  async function handleDelete(id) {


    const response = await api_amr.post("/delete_preco_registrado", {
      'id_preco': id
    },
      { headers: { 'x-api-key': await getToken() } });

    if (response.data === 'OK') {
      alert('deletado com sucesso')
      fetchPrecos();
    } else {
      alert('Ocorreu um erro, tente novamente');
    }
  };

  const filteredData = precos.filter((row) =>
    row.empresa.toLowerCase().includes(filter.toLowerCase()) ||
    row.produto_abreviacao.toLowerCase().includes(filter.toLowerCase())
  );

  function adjustDateToLocal(dateString) {
    if (!dateString) return '';
    const date = new Date(dateString);
    const localDate = new Date(date.getTime() + date.getTimezoneOffset() * 60000);
    return localDate.toISOString().split('T')[0];
  }
  return (
    <>
      <form onSubmit={(e) => { e.preventDefault(); handleAdicionaPreco(); }}>
        {carregando && <CircularProgress />}
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={2}>
            <Autocomplete
              options={materias}
              getOptionLabel={(option) => option.label}
              onChange={(e, value) => setMateriaSelecionada(value)}
              renderInput={(params) => (
                <TextField {...params} label="Matéria" variant="outlined" fullWidth />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Autocomplete
              options={embalagens}
              getOptionLabel={(option) => option.label}
              onChange={(e, value) => setEmbalagem(value)}
              renderInput={(params) => (
                <TextField {...params} label="Embalagem" variant="outlined" fullWidth />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Autocomplete
              options={portos}
              getOptionLabel={(option) => option.label}
              onChange={(e, value) => setPortoSelecionado(value)}
              renderInput={(params) => (
                <TextField {...params} label="Porto" variant="outlined" fullWidth />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <Autocomplete
              options={cidades}
              getOptionLabel={(option) => option.label}
              onChange={(e, value) => setCidadeSelecionada(value)}
              renderInput={(params) => (
                <TextField {...params} label="Cidade" variant="outlined" fullWidth />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <Autocomplete
              options={empresas}
              getOptionLabel={(option) => option.label || ""} // Handles potential empty labels
              freeSolo // Allows free text input
              onInputChange={(e, newInputValue) => {
                SetEmpresaSelecionada(newInputValue); // Update as user types
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Empresa"
                  variant="outlined"
                  fullWidth
                />
              )}
            />
          </Grid>

          {isAssociado ? (
            <Grid item xs={12} sm={6} md={2}>
              <Autocomplete
                options={associados}
                getOptionLabel={(option) => option.label}
                onChange={(e, value) => setAssociado(value)}
                renderInput={(params) => (
                  <TextField {...params} label="Associado" variant="outlined" fullWidth />
                )}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={6} md={2}>
              <Autocomplete
                options={fontes}
                getOptionLabel={(option) => option.label || ""} // Handles potential empty labels
                freeSolo // Allows free text input
                onInputChange={(e, newInputValue) => {
                  setFonteSelecionada(newInputValue); // Update as user types
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Fonte"
                    variant="outlined"
                    fullWidth
                  />
                )}
              />

            </Grid>
          )}

          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              label="Volume TON"
              type="number"
              value={volume === 0 ? '' : volume}
              onChange={(e) => setVolume(Number(e.target.value))}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              label="Frete R$"
              type="number"
              value={frete === 0 ? '' : frete}
              onChange={(e) => setFrete(Number(e.target.value))}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              label="Preço Compra USD"
              type="number"
              value={precoCompra === 0 ? '' : precoCompra}
              onChange={(e) => setPrecoCompra(Number(e.target.value))}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              label="Dólar"
              type="number"
              value={dolar === 0 ? '' : dolar}
              onChange={(e) => setDolar(Number(e.target.value))}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              label="Data"
              type="date"
              value={data}
              onChange={(e) => setData(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              label="Data de Pagamento"
              type="date"
              value={dataPagamento}
              onChange={(e) => setDataPagamento(e.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              label="Juros BRL"
              type="number"
              value={jurosBRL}
              onChange={(e) => setJurosBRL(Number(e.target.value))}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <TextField
              fullWidth
              label="Juros USD"
              type="number"
              value={jurosUSD}
              onChange={(e) => setJurosUSD(Number(e.target.value))}
              variant="outlined"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isAssociado}
                  onChange={(e) => setIsAssociado(e.target.checked)}
                />
              }
              label="Associado"
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={atualizar}
              fullWidth
            >
              {atualizar ? <CircularProgress size={24} /> : "Salvar"}
            </Button>
          </Grid>
        </Grid>
      </form>

      <TextField
        label="Filtrar por Empresa ou Produto"
        fullWidth
        margin="normal"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
        variant="outlined"
      />

      <TableContainer component={Paper} className={classes.container}>
        <Table className={classes.table} aria-label="precos registrados table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.headerCell}>Data Compra</TableCell>
              <TableCell className={classes.headerCell}>Empresa</TableCell>
              <TableCell className={classes.headerCell}>Origem</TableCell>
              <TableCell className={classes.headerCell}>Destino</TableCell>
              <TableCell className={classes.headerCell}>Volume (T)</TableCell>
              <TableCell className={classes.headerCell}>Fonte</TableCell>
              <TableCell className={classes.headerCell}>Produto</TableCell>
              <TableCell className={classes.headerCell}>Embalagem</TableCell>
              <TableCell className={classes.headerCell}>FOB plataforma $</TableCell> {/* Previously 'total_costs' */}
              <TableCell className={classes.headerCell}>Preço Pago $</TableCell> {/* Moved here */}
              <TableCell className={classes.headerCell}>CFR Equiv. $</TableCell> {/* Previously 'cfr_eqv' */}
              <TableCell className={classes.headerCell}>CFR Plataforma. $</TableCell> {/* Previously 'cfr_eqv' */}

              <TableCell className={classes.headerCell}>Margem $</TableCell>
              <TableCell className={classes.headerCell}>Associado</TableCell>
              <TableCell className={classes.headerCell}>Data Pagamento</TableCell>
              <TableCell className={classes.headerCell}>Juros</TableCell>
              <TableCell className={classes.headerCell}>Deletar</TableCell>


            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row) => (
              <TableRow key={row.id}>
                <TableCell className={classes.headerCell}>{adjustDateToLocal(row.data)}</TableCell>
                <TableCell className={classes.headerCell}>{row.empresa}</TableCell>
                <TableCell className={classes.headerCell}>{row.origem_cidade}-{row.origem_UF}</TableCell>
                <TableCell className={classes.headerCell}>{row.destino_cidade}-{row.destino_UF}</TableCell>
                <TableCell className={classes.headerCell}>{row.volume}</TableCell>
                <TableCell className={classes.headerCell}>{row.fonte_dados}</TableCell>
                <TableCell className={classes.headerCell}>{row.produto_abreviacao}</TableCell>
                <TableCell className={classes.headerCell}>{row.embalagem}</TableCell>
                <TableCell className={classes.headerCell}>{row.total_costs}</TableCell> {/* Previously 'FOB plataforma' */}

                <TableCell className={classes.headerCell}>{row.preco_compra}</TableCell> {/* Moved here */}
                <TableCell className={classes.headerCell}>{row.cfr_eqv}</TableCell> {/* Previously 'CFR Equiv.' */}
                <TableCell className={classes.headerCell}>{row.cfr_semana}</TableCell> {/* Previously 'CFR Equiv.' */}

                <TableCell className={classes.headerCell}>{row.margem}</TableCell>
                <TableCell className={classes.headerCell}>{row.name_associado}</TableCell>
                <TableCell className={classes.headerCell}>{row.data_pagamento !== 'None' ? adjustDateToLocal(row.data_pagamento) : ''}</TableCell>

                <TableCell className={classes.headerCell}>{row.juros_usd !== -1 ? 'USD:' + row.juros_usd + '%' : null}{row.juros_brl !== -1 ? 'BRL:' + row.juros_brl + '%' : null} </TableCell>
                <TableCell className={classes.headerCell}><Button onClick={() => handleDelete(row.id)}>Deletar</Button></TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default RegistradorPrecos;
