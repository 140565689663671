import React, { useEffect, useState } from "react";
import { getToken } from '../../utils/utils';
import api_amr from '../../services/api_amr';
import { Autocomplete } from "@material-ui/lab";
import { TextField, Button, Card, CardContent, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: theme.spacing(4),
    },
    card: {
        minWidth: 275,
        padding: theme.spacing(2),
        boxShadow: theme.shadows[3],
    },
    formField: {
        marginBottom: theme.spacing(2),
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(2),
    },
    table: {
        minWidth: 650,
    },
    editForm: {
        marginTop: theme.spacing(4),
    },
}));

function ConfigFazendasEdicao(props) {
    const [cidadesList, setCidadesList] = useState([]);
    const [associados, setAssociados] = useState([]);
    const [selectedAssociado, setSelectedAssociado] = useState(null);
    const [farms, setFarms] = useState([]);
    const [editFarm, setEditFarm] = useState(null);
    const [users, setUsers] = useState('')

    const classes = useStyles();

    useEffect(() => {
        async function fetchAssociados() {
            const response_associados = await api_amr.get('/get_associados', { headers: { 'x-api-key': getToken() } });
            setAssociados(response_associados.data);
        }
        fetchAssociados();
    }, []);

    useEffect(() => {
        async function fetchCidades() {
            const response_cidades_list = await api_amr.get('/return_cidades_brasil', { headers: { 'x-api-key': getToken() } });
            setCidadesList(response_cidades_list.data);
        }
        fetchCidades();
    }, []);

    useEffect(() => {
        if (selectedAssociado) {
            fetchFarms(selectedAssociado.id_associado);
        }
    }, [selectedAssociado]);

    async function fetchFarms(id_associado) {
        const response = await api_amr.get(`/get_farms?id_associado=${id_associado}`, { headers: { 'x-api-key': getToken() } });
        setFarms(response.data);
    }

    function handleInputChange(event) {
        const { name, value } = event.target;
        setEditFarm({ ...editFarm, [name]: value });
    }

    function handleCityChange(event, newValue) {
        setEditFarm({ ...editFarm, cidade: newValue ? newValue.cidade : '', estado: newValue ? newValue.UF : '' });
    }

    async function handleUpdateFarm() {
        try {
            const response = await api_amr.post('/update_farm', editFarm, { headers: { 'x-api-key': getToken() } });
            if (response.data === 'OK') {
                console.log('Fazenda atualizada com sucesso');
                fetchFarms(selectedAssociado.id_associado); // Atualizar a lista de fazendas após a edição
                setEditFarm(null); // Limpar o formulário de edição
            } else {
                console.error('Erro ao atualizar fazenda:', response.data);
            }
        } catch (error) {
            console.error('Erro ao atualizar fazenda:', error);
        }
    }

    async function handleDeleteFarm() {
        try {
            const response = await api_amr.post('/delete_farm', editFarm, { headers: { 'x-api-key': getToken() } });
            if (response.data === 'OK') {
                console.log('Fazenda deletada com sucesso');
                fetchFarms(selectedAssociado.id_associado); // Atualizar a lista de fazendas após a edição
                setEditFarm(null); // Limpar o formulário de edição
            } else if (response.data === 'NOKbanco') {
                alert('Existe uma demanda vinculada a essa fazenda, remova todas as demandas antes de deletar a fazenda')
            }
            else {
                console.error('Erro ao deleta fazenda:', response.data);
            }
        } catch (error) {
            console.error('Erro ao deletar fazenda:', error);
        }
    }
    async function setSelectFarm(farm) {
        setEditFarm(farm)
        const response_Users = await api_amr.get('/get_users', { params: { 'associado': farm.id_associado }, headers: { 'x-api-key': getToken() } });
        setUsers(response_Users.data);
    }
    return (
        <div className={classes.container}>
            <Card className={classes.card}>
                <CardContent>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Editar Fazendas
                    </Typography>
                    <Autocomplete
                        options={associados}
                        getOptionLabel={(option) => option.name_associado}
                        onChange={(event, newValue) => setSelectedAssociado(newValue)}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Selecionar Associado"
                                variant="outlined"
                                className={classes.formField}
                            />
                        )}
                    />
                    {selectedAssociado && (
                        <>
                            <TableContainer component={Paper}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Nome Fazenda</TableCell>
                                            <TableCell>CAR Fazenda</TableCell>
                                            <TableCell>Usuário</TableCell>
                                            <TableCell>Estado</TableCell>
                                            <TableCell>Cidade</TableCell>
                                            <TableCell>CEP</TableCell>
                                            <TableCell>Endereço</TableCell>
                                            <TableCell>Área</TableCell>
                                            <TableCell>Ações</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {farms.map((farm) => (
                                            <TableRow key={farm.id_farm}>
                                                <TableCell>{farm.nome_fazenda}</TableCell>
                                                <TableCell>{farm.car_fazenda}</TableCell>
                                                <TableCell>{farm.name}</TableCell>

                                                <TableCell>{farm.estado}</TableCell>
                                                <TableCell>{farm.cidade}</TableCell>
                                                <TableCell>{farm.cep}</TableCell>
                                                <TableCell>{farm.endereco}</TableCell>
                                                <TableCell>{farm.area}</TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        onClick={() => setSelectFarm(farm)}
                                                    >
                                                        Editar
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            {editFarm && (
                                <Grid container spacing={3} className={classes.editForm}>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Nome Fazenda"
                                            name="nome_fazenda"
                                            variant="outlined"
                                            className={classes.formField}
                                            value={editFarm.nome_fazenda}
                                            onChange={handleInputChange}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        {users !== '' ?
                                            < Autocomplete
                                                options={users}
                                                getOptionLabel={(option) => option.name}

                                                onChange={(event, newValue) => setEditFarm({ ...editFarm, id_usuario: newValue ? newValue.id : '' })}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Selecionar Usuário"
                                                        variant="outlined"
                                                        className={classes.formField}
                                                       
                                                    />
                                                )}
                                            /> : null}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="CAR Fazenda"
                                            name="car_fazenda"
                                            variant="outlined"
                                            className={classes.formField}
                                            value={editFarm.car_fazenda}
                                            onChange={handleInputChange}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Autocomplete
                                            options={cidadesList}
                                            getOptionLabel={(option) => `${option.UF} - ${option.cidade}`}
                                            onChange={handleCityChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Cidade"
                                                    variant="outlined"
                                                    className={classes.formField}
                                                    size="small"
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label="Estado"
                                            name="estado"
                                            variant="outlined"
                                            className={classes.formField}
                                            value={editFarm.estado}
                                            onChange={handleInputChange}
                                            size="small"
                                            disabled
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="CEP"
                                            name="cep"
                                            variant="outlined"
                                            className={classes.formField}
                                            value={editFarm.cep}
                                            onChange={handleInputChange}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Endereço"
                                            name="endereco"
                                            variant="outlined"
                                            className={classes.formField}
                                            value={editFarm.endereco}
                                            onChange={handleInputChange}
                                            size="small"
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Área"
                                            name="area"
                                            variant="outlined"
                                            className={classes.formField}
                                            value={editFarm.area}
                                            onChange={handleInputChange}
                                            size="small"
                                            type="number"

                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            className={classes.button}
                                            onClick={handleUpdateFarm}
                                        >
                                            Atualizar
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="red"
                                            className={classes.button}
                                            onClick={handleDeleteFarm}
                                        >
                                            Deletar
                                        </Button>
                                    </Grid>

                                </Grid>
                            )}
                        </>
                    )}
                </CardContent>
            </Card>
        </div>
    );
}

export default ConfigFazendasEdicao;
