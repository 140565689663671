import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { getToken, checarPermissao } from '../../utils/utils'
import api_amr from '../../services/api_amr'
import Loader from 'react-loader-spinner';
import { Autocomplete } from "@material-ui/lab";
import { TextField } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import CircularProgress from '@material-ui/core/CircularProgress';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import IconButton from '@material-ui/core/IconButton';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Switch from '@material-ui/core/Switch';

function ConfigPortos(props) {

    const [nomePorto, setNomePorto] = useState('')
    const [cidade, setCidade] = useState('')
    const [cidadesList, setCidadesList] = useState('')
    const [infos, setInfos] = useState('')
    const [permitido, setPermitido] = useState(false)

    const [toUpdate, setToUpdate] = useState([]);
    const [portos, setPortos] = useState('')
    const [selectedPorto, setSelectedPorto] = useState('')
    const [portosArray, setPortosArray] = useState('')
    const [configPortos, setConfigPortos] = useState('')
    const [desestiva, setDesestiva] = useState('')
    const [demurrage, setDemurrage] = useState('')
    const [importation, setImportation] = useState('')
    const [bag, setBag] = useState('')
    const [ind, setInd] = useState('')
    const [insurance, setInsurance] = useState('')
    const [inspection, setInspection] = useState('')
    const [afrmm, setAfrmm] = useState('')
    const [losses, setLosses] = useState('')
    const [custom, setCustom] = useState('')
    const [icms, setIcms] = useState('')
    const [vira, setVira] = useState('')
    const [id, setId] = useState('')
    const [value, setValue] = useState('')

    // const ToUpdate = []





    const classes = useStyles();

    useEffect(() => {
        async function fetchMyAPI() {

            setPermitido(await checarPermissao('configuracoes_portos', props));

            const response_return_portos = await api_amr.get('/return_portos', { params: { 'all': 1 }, headers: { 'x-api-key': getToken() } });
            setPortos(response_return_portos.data)
            setPortosArray(Array.from({ length: response_return_portos.data.length }, i => i = false));


            getConfigPortos()
            getCidadesList()
        }
        fetchMyAPI();
    }, []);


    async function cadastraPorto() {

        if ((nomePorto === '') | (cidade === '') | (infos === '')) {
            alert('Alguns campos obrigatórios estão incompletos\n' + (nomePorto === '' ? 'Nome do Porto\n' : '')
                + (cidade === '' ? 'Cidade\n' : ''))
            return
        }
        const response_cadastrar_porto = await api_amr.post('/cadastro_porto',
            {
                'nomePorto': nomePorto, 'id_cidade': cidade.id, 'infos': infos
            }, { headers: { 'x-api-key': getToken() } }
        );
        if (response_cadastrar_porto.data === 'OK') {
            alert('Porto adicionado com sucesso!')
            window.location.reload()
        }
    }
    // e.target.value
    async function getCidadesList() {

        const response_cidades_list = await api_amr.get('/return_cidades_brasil', { headers: { 'x-api-key': getToken() } })
        setCidadesList(response_cidades_list.data)
    }

    async function alterSelected(index, estadoBotao) {
        let aux = Array.from({ length: portosArray.length }, i => i = false)
        aux[index] = estadoBotao
        setPortosArray(JSON.parse(JSON.stringify(aux)))

        if (estadoBotao === true) {
            setSelectedPorto(index)
        } else {
            setSelectedPorto('')
        }
    }

    async function getConfigPortos() {
        const response_return_config_portos = await api_amr.get('/return_config_portos', { params: { 'all': 1 }, headers: { 'x-api-key': getToken() } });
        setConfigPortos(response_return_config_portos.data)
    }

    const handleUpdateMaterias = (item, item_, target) => {

        let aux = toUpdate;
        aux[configPortos[item]['id_porto'] + '-' + item_] = target;
        setToUpdate(aux)
    }
    async function handleConfigChange() {
        let aux = '';

        Object.keys(toUpdate).forEach((item) => (aux = aux + item + '#' + toUpdate[item] + ';'));
        const response = await api_amr.post('/update_config_portos',
            {
                'toUpdate': aux,
            }, { headers: { 'x-api-key': getToken() } });

        if (response.data === 'NOK') {
            alert('Erro ao atualizar configurações');
            window.location.reload();
        }
        else {
            alert('Configurações alteradas com sucesso');
            window.location.reload();
        }
    }


    return (
        permitido ?
            <div className={classes.container} >
                <div >
                    <h2 className={classes.inputh1}>Cadastrar Portos</h2>

                    <div style={{ display: 'flex', flexDirection: 'column', }}>
                        {/* inicio de inserção de dados */}
                        {/* Inserir Nome */}
                        <TextField
                            key='Nome Porto'
                            variant='outlined'
                            placeholder='Nome Porto'
                            className={classes.autoCompleteFieldBig}
                            onChange={(event) => setNomePorto(event.target.value)}
                            InputProps={{
                                disableUnderline: true,
                                classes: { notchedOutline: classes.noBorder }
                            }}


                        />
                        <TextField
                            key='infos'
                            variant='outlined'
                            placeholder='Informações do Porto'
                            className={classes.autoCompleteFieldBig}
                            onChange={(event) => setInfos(event.target.value)}
                            InputProps={{
                                disableUnderline: true,
                                classes: { notchedOutline: classes.noBorder }
                            }}

                        />
                        {/* Lista de cidade */}
                        <Autocomplete
                            options={cidadesList}
                            getOptionLabel={(option) => (option.id + ' - ' + option.cidade + ' - ' + option.UF)}
                            className={classes.autoCompleteFieldBig}

                            onChange={(e, v) => (setCidade(v),console.log(cidade))}

                            classes={{
                                input: classes.input,

                            }}
                            renderInput={(params) => <TextField
                                {...params}
                                placeholder="Selecionar Cidade"
                                variant='outlined'
                                value={cidade}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                    classes: { notchedOutline: classes.noBorder }
                                }}

                            />}
                        />
                        <Button onClick={() => cadastraPorto()} className={classes.calcButton}>Cadastrar Porto</Button>
                        {/* Fim de inserção de dados */}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '98%' }}>
                        {/* Inicio Tabela Portos */}
                        {/* <h2 className={classes.inputh1}>Portos Cadastrados</h2>
                        <TableContainer className={classes.tableModels}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell width={10}>
                                            id
                                        </TableCell>
                                        <TableCell width={150}>
                                            Nome
                                        </TableCell>
                                        <TableCell width={50}>
                                            cidade
                                        </TableCell>
                                        <TableCell width={50}>
                                            UF
                                        </TableCell>
                                        <TableCell width={50}>
                                            infos
                                        </TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Object.keys(portos).map((item, index) => (
                                        <TableRow key={index}  >
                                            <TableCell style={{ display: 'flex', flexDirection: 'row' }}>
                                                {portos[item].id}
                                            </TableCell>
                                            <TableCell  >
                                                {portos[item].nome_porto}
                                            </TableCell>
                                            <TableCell  >
                                                {portos[item].cidade}
                                            </TableCell>
                                            <TableCell  >
                                                {portos[item].UF}
                                            </TableCell>
                                            <TableCell  >
                                                {portos[item].infos}
                                            </TableCell>


                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer> */}
                        {/* Fim Tabela Portos */}

                        {/* Inicio Tabela Config_Portos */}
                        <h2 className={classes.inputh1}>Configurações Portos</h2>

                        <div>
                            {configPortos !== '' ? <TableContainer className={classes.tableModels}>
                                <Table className={classes.table} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">
                                                id
                                            </TableCell>
                                            <TableCell align="center">

                                            </TableCell>
                                            <TableCell align="center">
                                                Nome do Porto
                                            </TableCell>
                                            <TableCell align="center">
                                                Cidade - Estado
                                            </TableCell>
                                            <TableCell align="center">
                                                desestiva
                                            </TableCell>
                                            <TableCell align="center">
                                                demurrage
                                            </TableCell>
                                            <TableCell align="center">
                                                importation_tax
                                            </TableCell>
                                            <TableCell align="center">
                                                bag_rs
                                            </TableCell>
                                            <TableCell align="center">
                                                ind_rs
                                            </TableCell>
                                            <TableCell align="center">
                                                insurance
                                            </TableCell>
                                            <TableCell align="center">
                                                inspection
                                            </TableCell>
                                            <TableCell align="center">
                                                afrmm
                                            </TableCell>
                                            <TableCell align="center">
                                                losses
                                            </TableCell>
                                            <TableCell align="center">
                                                custom_exp
                                            </TableCell>
                                            <TableCell align="center">
                                                icms
                                            </TableCell>
                                            <TableCell align="center">
                                                vira_rs
                                            </TableCell>


                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {Object.keys(configPortos).map((item, index) => (
                                            <TableRow key={index} style={{ backgroundColor: portosArray[index] === true ? '#e9f5d6' : 'white' }}>
                                                <TableCell align="center">
                                                    {configPortos[item].id}
                                                </TableCell>
                                                <TableCell align="center">

                                                    {portosArray[index] === true ? <IconButton
                                                        style={{ color: '#A3CC51' }}
                                                        aria-label="toggle password visibility"
                                                        onClick={() => alterSelected(index, false)}
                                                    >
                                                        < CheckCircleOutlineIcon />
                                                    </IconButton> : <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => alterSelected(index, true)}

                                                    >
                                                        <RadioButtonUncheckedIcon />
                                                    </IconButton>}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {configPortos[item].nome_porto}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {configPortos[item].cidade} - {configPortos[item].UF}
                                                </TableCell>
                                                {Object.keys(configPortos[item]).map((item_elemnt, index_element) =>
                                                    ['id', 'id_porto', 'nome_porto', 'configs', 'cidade', 'UF'].includes(item_elemnt) ? null :
                                                        <TableCell key={item_elemnt} align="center" style={{ padding: 0 }}>
                                                            {portosArray[index] !== true ? (toUpdate[configPortos[item]['id_porto'] + '-' + item_elemnt] !== undefined ? toUpdate[configPortos[item]['id_porto'] + '-' + item_elemnt] : configPortos[item][item_elemnt]) :
                                                                <TextField
                                                                    key='item_elemnt'
                                                                    defaultValue={toUpdate[configPortos[item]['id_porto'] + '-' + item_elemnt] !== undefined ? toUpdate[configPortos[item]['id_porto'] + '-' + item_elemnt] : configPortos[item][item_elemnt]}
                                                                    variant='outlined'
                                                                    type="number" 
                                                                                                                                                                                                       
                                                                    max={9999999999} 
                                                                    onChange={(e) =>  e.target.value < 0  ? (handleUpdateMaterias(item, item_elemnt, 0)) : handleUpdateMaterias(item, item_elemnt, e.target.value)
                                                                    }                                                                  
                                                                    // onChange={(e) => handleUpdateMaterias(item, item_elemnt, e.target.value)}
                                                                    style={{ padding: 0 }}
                                                                    InputProps={{
                                                                        min: 0,
                                                                        step:0.01,
                                                                        disableUnderline: true,
                                                                        classes: { notchedOutline: classes.noBorder },

                                                                    }}
                                                                />}
                                                        </TableCell>)}

                                            </TableRow>
                                        ))
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer> : null}
                            <Button onClick={() => handleConfigChange()} className={classes.calcButton}>Atualizar Configurações</Button>

                        </div>
                        {/* Fim Tabela Config_Portos */}
                    </div>
                </div>


            </div > : <Loader type="ThreeDots" color="#A3CC51" height="100" width="100" />
    )
}

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 100,
    },
    inputsDiv: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        marginTop: 40,

    },
    noBorder: {
        border: "none",
    },
    autoCompleteFieldSmall: {
        marginTop: 15,
        minWidth: 104,
        maxWidth: 104,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteField: {
        marginTop: 15,
        minWidth: 228,
        maxWidth: 228,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    autoCompleteFieldBig: {
        marginTop: 15,
        width: 471,
        borderRadius: 6,
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'

    },
    textField: {
        fontFamily: 'Poppins',
        fontSize: '9px',
        width: '352px',
        backgroundColor: 'white',
        height: '50px',
        display: 'flex',
        flexDirection: 'row',
        alignItens: 'center',
        borderRadius: 6,

        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)'
    },
    input: {
        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#383838',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
    },
    inputCheckbox: {

        fontFamily: 'Poppins',
        fontSize: '14px',
        color: '#000000'
    },
    inputh1: {
        fontFamily: 'Poppins',

        color: '#383838'
    },
    root: {
        "&$checked": {
            color: "black",
            backgroundColor: 'white'
        }
    },
    checked: {
        borderColor: 'black'
    },
    calcButton: {
        fontFamily: 'Poppins',
        height: 40,
        fontSize: '10px',
        background: '#A3CC51',
        width: '146px',
        color: 'white',
        borderRadius: 6,
        marginTop: 15,
        '&:hover': {
            background: "rgba(163, 204, 81, 0.7)",
        },

    },
    calcDiv: {
        marginTop: 40,
        width: '146px',
        marginLeft: 325,
        height: 45
    },
    nutriDiv: {
        paddingRight: 28,
        display: 'flex',
        flexDirection: 'column',
        maxWidth: 228
    },
    inputForm: {
        width: '50%',
        fontFamily: 'Poppins',
        color: '#383838'
    },
    toolTipIconStyle: {
        marginLeft: 'auto', width: 15, height: 15, color: 'black', opacity: 0.6
    }, table: {
        minWidth: 400,
        maxWidth: '100%'
    },
    rowSize: {
        maxWidth: 500
    },
    tableModels: {
        marginTop: 20,
        maxWidth: '100%',
        boxShadow: '0px 17px 80px rgba(0, 0, 0, 0.07), 0px 7.10219px 33.4221px rgba(0, 0, 0, 0.0503198), 0px 3.79717px 17.869px rgba(0, 0, 0, 0.0417275), 0px 2.12866px 10.0172px rgba(0, 0, 0, 0.035), 0px 1.13052px 5.32008px rgba(0, 0, 0, 0.0282725), 0px 0.470434px 2.21381px rgba(0, 0, 0, 0.0196802)',
        maxHeight: 700,
        overflow: 'auto'
    },
}));

export default ConfigPortos;
